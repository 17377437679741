import React from 'react';
import styled from 'styled-components';
import { FaShareAlt } from 'react-icons/fa';
import {useTranslation} from 'react-i18next';

interface ShareButtonProps {
    url: string;
    text: string;
    title: string;
}

const ShareButton: React.FC= () => {
    const { t, i18n } = useTranslation();
    const url = 'https://www.bdsmlab.me/';
    const text = t('shareText');
    const title = t('shareTitle');

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title,
                text,
                url,
            }).then(() => {
                console.log(t('shareSuccess'));
            }).catch((error) => {
                console.error(t('shareError'), error);
            });
        } else {
            alert(t('shareNotSupported'));
        }
    };

    return (
        <Button onClick={handleShare}>
            <FaShareAlt />
            {t('share')}
        </Button>
    );
};

export default ShareButton;

const Button = styled.button`
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #4CAF50;
    color: white;
    border-radius: 5px;
    outline: none;

    svg {
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
`;
