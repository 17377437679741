import React from 'react';
import styled from 'styled-components';
import { FaCheck } from "react-icons/fa";
import {useTranslation} from 'react-i18next';

interface QuestionProps {
    question: {
        id: number;
        text: string;
    };
    onAnswer: (questionId: number, score: number) => void;
    selectedAnswer: number | null | undefined;
}

const Question: React.FC<QuestionProps> = ({ question, onAnswer, selectedAnswer }) => {
    const { t } = useTranslation();


    const handleAnswer = (score: number) => {
        onAnswer(question.id, score);
    };

    return (
        <QuestionContainer>
            <QuestionText>{question.text}</QuestionText>
            <ButtonArea>
                <Label>{t('label.notMuch')}</Label>
                {[0, 1, 2, 3, 4, 5].map((score) => (
                    <AnswerButton
                        key={score}
                        onClick={() => handleAnswer(score)}
                        selected={selectedAnswer !== null && selectedAnswer === score}
                        $position={score}
                    >
                        {selectedAnswer === score && <CheckAnswer $size={score} />}
                    </AnswerButton>
                ))}
                <Label>{t('label.totally')}</Label>
            </ButtonArea>
        </QuestionContainer>
    );
};

export default Question;

const QuestionContainer = styled.div`
    padding: 2rem 0px;
    border-bottom: 1px solid #ccc;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const QuestionText = styled.p`
    color: ${(props) => props.theme.colors.text};
    margin: 0 0 10px 0;
    font-weight: 700;
    font-size: 1.4rem;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 1.1rem;
    }
`;

const ButtonArea = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    max-width: 1024px;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;

const Label = styled.span`
    font-size: 1rem;
    color: ${(props) => props.theme.colors.text};
    font-weight: 600;
`;

const AnswerButton = styled.button<{ selected: boolean; $position: number }>`
    display: inline-block;
    width: ${props => {
        if (props.$position === 0 || props.$position === 5) return '35px';
        if (props.$position === 1 || props.$position === 4) return '27px';
        return '20px';
    }};
    height: ${props => {
        if (props.$position === 0 || props.$position === 5) return '35px';
        if (props.$position === 1 || props.$position === 4) return '27px';
        return '20px';
    }};
    margin: 0 5px;
    background-color: ${props => {
        if (props.selected) {
            return props.$position <= 2 ? '#EBD993' : '#7B002B';
        }
        return props.theme.colors.background;
    }};
    color: ${props => (props.selected ? 'white' : 'black')};
    border: 3px solid grey;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${props => {
            if (props.selected) {
                return props.$position <= 2 ? '#EBD993' : '#7B002B';
            }
            return props.$position <= 2 ? '#e4d8a7' : '#7c2745';
        }};
    }
`;

const CheckAnswer = styled(FaCheck)<{$size: number}>`
    font-size: ${props => {
        if (props.$size === 0 || props.$size === 5) return '17px';
        if (props.$size === 1 || props.$size === 4) return '13px';
        return '10px';
    }};
    color: ${props => props.$size <= 2 ? '#000' : '#fff'};
`;

