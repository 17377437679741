import { createGlobalStyle, DefaultTheme } from 'styled-components';

const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
    body {
        background-color: ${(props) => props.theme.colors.background};
        color: ${(props) => props.theme.colors.text};
        //font-family: Arial, Helvetica, sans-serif;
        margin: 0;
        padding: 0;
        transition: all 0.25s linear;
    }
`;

export default GlobalStyle;
