import React from 'react';

type Props = {

};
const ErrorPage = (props: Props) => {
    return (
        <div>
            ErrorPage
        </div>
    );
};

export default ErrorPage;
