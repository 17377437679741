import React from 'react';
import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';
import ChartComponent from '../Chart';
import { useTranslation } from 'react-i18next';
import { bdsmTendencies, maxScores } from '../../data/data';
import ShareButton from '../ShareButton';

interface Score {
    tendency: string;
    koreanName: string;
    score: number;
}

const Results: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const scores: Score[] = location.state?.scores || [];

    const normalizeScores = (scores: Score[]): Score[] => {
        return scores.map(scoreObj => {
            const maxScore = maxScores[scoreObj.tendency] || 1;
            const normalizedScore = (scoreObj.score / maxScore) * 100;
            return {
                ...scoreObj,
                score: parseFloat(normalizedScore.toFixed(1))
            };
        });
    };

    const calculateResults = (): { type: string, description: string } => {
        if (scores.length !== bdsmTendencies.length) {
            return { type: t("dataError"), description: "" }; // 데이터 오류 처리
        }
        const normalizedScores = normalizeScores(scores);
        const maxScoreObj = normalizedScores.reduce((prev, current) => (prev.score > current.score) ? prev : current);
        const tendency = bdsmTendencies.find(t => t.englishName === maxScoreObj.tendency);
        return tendency
            ? { type: t(`bdsmTendencies.${tendency.englishName}.name`), description: t(`bdsmTendencies.${tendency.englishName}.description`) }
            : { type: t("noTendency"), description: "" };
    };

    const result = calculateResults();

    const normalizedScores = normalizeScores(scores)
        .sort((a, b) => b.score - a.score);

    return (
        <ResultsContainer>
            <ResultArea>
                <HeaderContainer>
                    <ResultsTitle>{t("yourMainBDSMTendency")}: <TendencyName>{result.type}</TendencyName></ResultsTitle>
                    <ResultsDescription>
                        {t("resultsDescription", { type: result.type, description: result.description })}
                    </ResultsDescription>
                </HeaderContainer>
                <ChartWrapper>
                    <ChartComponent data={normalizedScores} />
                </ChartWrapper>
                <ScoreList>
                    {normalizedScores.map((scoreObj, index) => (
                        <ScoreItem key={index} >
                            <ScoreLink to={`/tendencies/${scoreObj.tendency.toLowerCase()}`}>
                                <TendencyName>{t(`bdsmTendencies.${scoreObj.tendency}.name`)}</TendencyName>
                                <ScoreBar $score={scoreObj.score}>
                                    <ScoreText>{scoreObj.score}%</ScoreText>
                                </ScoreBar>
                            </ScoreLink>
                        </ScoreItem>
                    ))}
                </ScoreList>
                <ShareArea>
                    <ShareButton />
                </ShareArea>
            </ResultArea>

        </ResultsContainer>
    );
};

export default Results;

const ResultsContainer = styled.div`
    padding: 20px;
    display: flex;
    justify-content: center;
    //font-family: Arial, sans-serif;
`;

const ResultArea = styled.div`
    max-width: 1024px;
`;

const HeaderContainer = styled.div`
    
    width: 100%;
`;

const ResultsTitle = styled.h1`
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: normal;
`;

const ResultsDescription = styled.p`
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 1.5;
`;

const ChartWrapper = styled.div`
    width: 100%;
    height: 600px;
    margin-bottom: 30px;
`;

const ScoreList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 30px 0;
    width: 100%;
`;

const ScoreItem = styled.li`
    margin-bottom: 5px;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: translateY(-3px);
    }
`;

const ScoreLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 10px;
`;

const TendencyName = styled.span`
    flex: 1;
    font-weight: bold;
    //margin-right: 10px;
`;

const ScoreBar = styled.div<{ $score: number }>`
    flex: 3;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: ${props => props.$score}%;
        background-color: ${(props) => props.theme.colors.text};
        transition: width 0.5s ease-in-out;
    }
`;

const ScoreText = styled.span`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-weight: bold;
    color: #333;
`;

const ShareArea = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
`;
