import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
    colors: {
        background: '#F4F5F6',
        text: '#17171b',
        primary: '#404040',
        secondary: '#8C8C8C',
    },
};

export const darkTheme: DefaultTheme = {
    colors: {
        background: '#17171b',
        text: '#F4F5F6',
        primary: '#D9D9D9',
        secondary: '#8C8C8C',
    },
};

