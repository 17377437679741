import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import * as Sentry from "@sentry/react";
import { ThemeProvider } from './styles/ThemeContext';
import reportWebVitals from './reportWebVitals';
import Layout from './components/layout';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './components/ErrorPage';
import TestPage from './components/pages/TestPage';
import ResultsPage from './components/pages/ResultsPage';
import TendenciesMainPage from './components/pages/TendenciesMainPage';
import DetailPage from './components/pages/TendencyDetail';


Sentry.init({
    dsn: process.env.REACT_APP_SENRTY_DSN || 'https://39343cb45975e869b5c7ad4d40cb80d2@o4506829305217024.ingest.us.sentry.io/4507767438311424',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
            colorScheme: "system",
        }),

    ],
    debug: false,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/(www\.)?bdsmlab\.me/
    ],
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
        // console.log("Sending event to Sentry:", event);
        // 에러가 발생할 때 사용자 피드백 대화상자 표시
        if (event.exception) {
            Sentry.showReportDialog({ eventId: event.event_id });
        }
        return event;
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

export const tendencies = [
    'Dominant',
    'Submissive',
    'Switch',
    'Sadist',
    'Masochist',
    'Rigger',
    'RopeBunny',
    'MasterMistress',
    'Slave',
    'Hunter',
    'Prey',
    'Brat',
    'BratTamer',
    'DaddyMommy',
    'Little',
    'Owner',
    'Pet',
    'Spanker',
    'Spankee',
    'Boss',
    'Servant',
    'Degrader',
    'Degradee'
];

const tendencyRoutes = tendencies.map(tendency => ({
    path: `/tendencies/${tendency.toLowerCase()}`,
    element: <DetailPage tendency={tendency} />,
}));

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        errorElement: <ErrorPage />,
        children:[
            {
                path: "/",
                element: <App />,
            },
            {
                path: "/test",
                element: <TestPage />,
            },
            {
                path: "/results",
                element: <ResultsPage />,
            },
            {
                path: "/tendencies",
                element: <TendenciesMainPage />,
            },
            ...tendencyRoutes
        ]
    },
]);

root.render(
    <React.StrictMode>
        <ThemeProvider>
            <RouterProvider router={router} />
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
