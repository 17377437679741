import React, {lazy, Suspense} from 'react';
const MainPage = lazy(() => import('./components/pages/MainPage'));


const App: React.FC = () => {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <MainPage />
            </Suspense>
        </>
    );
};

export default App;
