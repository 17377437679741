import React, { useRef, useEffect, useContext } from 'react';
import Chart from 'chart.js/auto';
import { registerables } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

interface ChartProps {
    data: { tendency: string; koreanName: string; score: number }[];
}

Chart.register(...registerables);

const ChartComponent: React.FC<ChartProps> = ({ data }) => {
    const { t } = useTranslation();
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const chartRef = useRef<Chart | null>(null);
    const theme = useContext(ThemeContext);

    useEffect(() => {
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            if (ctx) {
                if (chartRef.current) {
                    chartRef.current.destroy();
                }

                chartRef.current = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: data.map(d => t(`bdsmTendencies.${d.tendency}.name`)),
                        datasets: [
                            {
                                label: t('scorePercentage'),
                                data: data.map(d => d.score),
                                backgroundColor: theme?.colors.primary,
                                borderColor: theme?.colors.secondary,
                                borderWidth: 1,
                            },
                        ],
                    },
                    options: {
                        indexAxis: 'y',
                        scales: {
                            x: {
                                beginAtZero: true,
                                max: 100,
                                title: {
                                    display: true,
                                    text: t('scorePercentage'),
                                    color: theme?.colors.text,
                                }
                            },
                            y: {
                                ticks: {
                                    autoSkip: false,
                                    maxTicksLimit: 20,
                                    color: theme?.colors.text,
                                    callback: function(value, index) {
                                        return data[index] ? t(`bdsmTendencies.${data[index].tendency}.name`) : value.toString();
                                    }
                                },
                                title: {
                                    display: true,
                                    text: t('tendency'),
                                    color: theme?.colors.text,
                                }
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                                labels: {
                                    color: theme?.colors.text,
                                },
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return context.raw + '%';
                                    },
                                },
                            },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    },
                });
            }
        }

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [data, t, theme]);

    return <canvas ref={canvasRef} />;
};

export default ChartComponent;
