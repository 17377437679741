// DetailPage.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import {useResponsive} from '../../hooks/useResponsive';
import {Link} from 'react-router-dom';

interface DetailPageProps {
    tendency: string | undefined;
}

interface TendencyData {
    name: string;
    description: string;
}

const DetailPage: React.FC<DetailPageProps> = ({ tendency }) => {

    const { t, i18n } = useTranslation();
    const [tendencyData, setTendencyData] = useState<TendencyData | null>(null);
    const {isDesktop, isTablet, isMobile} = useResponsive();


    useEffect(() => {
        const loadJson = async () => {
            try {
                const response = await fetch(`/locales/${i18n.language}/${tendency}.json`);
                const data = await response.json();
                setTendencyData(data);
            } catch (error) {
                console.error('Error fetching JSON:', error);
            }
        };

        loadJson();
    }, [tendency, i18n.language]);
    // explanation
    // const title = tendencyData?.name + t('explanation');

    return (
        <Container>
            <Area isDesktop={isDesktop}>
                {tendencyData ? (
                    <>
                        <Helmet>
                            <title>{tendencyData.name} {t('explanation')}</title>
                            <meta name="description" content={tendencyData.description} />
                        </Helmet>
                        <DescriptionTitle>{tendencyData.name} {t('explanation')}</DescriptionTitle>
                        <DesciptionContainer>
                            <ReactMarkdown className="prose">{tendencyData.description}</ReactMarkdown>
                        </DesciptionContainer>
                    </>
                ) : (
                    <p>{t('loading')}</p>
                )}
                <ButtonArea>
                    <Link to="/test">
                       <StartButton>
                            {t('start')}
                       </StartButton>
                    </Link>
                </ButtonArea>
            </Area>
        </Container>
    );
};

export default DetailPage;

const TendencyContainer = styled.div`
    padding: 20px;
`;

const Container = styled.div`
    width: 100%;
    
    display: flex;
    justify-content: center;
    
`

const Area = styled.div<{ isDesktop:boolean }>`
    max-width: 1024px;
    width: 100%;
    padding: ${(props) => (props.isDesktop ? '0px' : '12px')};

`

const DescriptionTitle = styled.h1`
    font-size: 2.6rem;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
`

const DesciptionContainer = styled.div`
    .prose {
        font-size: 1rem;
        line-height: 1.75;
        color: ${(props) => props.theme.colors.text};
    }

    .prose h1 {
        font-size: 2.25rem;
        line-height: 2.5rem;
        font-weight: 800;
        margin-bottom: 1rem;
    }

    .prose h2 {
        font-size: 1.875rem;
        line-height: 2.25rem;
        font-weight: 700;
        margin-bottom: 0.75rem;
    }

    .prose h3 {
        font-size: 1.5rem;
        line-height: 2.25rem;
        font-weight: 700;
        margin-top: 0.75rem;
    }

    .prose p {
        margin-bottom: 1.25rem;
    }

    .prose ul {
        list-style: inside;
    }

    .prose img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 1.5rem 0;
    }

    .prose blockquote {
        border-left: 4px solid #ddd;
        padding-left: 1rem;
        color: #555;
        font-style: italic;
    }
`
const ButtonArea = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
`;

const StartButton = styled.button`
    padding: 10px 20px;
    
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #45a049;
    }
`;


