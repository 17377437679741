import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MdLanguage } from "react-icons/md";

const options = [
    { value: 'en', label: 'English', icon: null },
    { value: 'ko', label: '한국어', icon: '/images/kr.svg' },
    { value: 'ja', label: '日本語', icon: '/images/jp.svg' },
    { value: 'th', label: 'ไทย', icon: '/images/th.svg' }
];

const LanguageSwitcher: React.FC = () => {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setIsOpen(false);
    };

    const currentOption = options.find(option => option.value === i18n.language);

    return (
        <DropdownContainer>
            <DropdownButton onClick={toggleDropdown}>
                {currentOption?.icon ? (
                    <LanguageImage src={currentOption.icon} alt={i18n.language} />
                ) : (
                    <LanguageStyled />
                )}
                {currentOption?.label}
            </DropdownButton>
            <DropdownMenu $isOpen={isOpen}>
                {options.map(option => (
                    <DropdownItem key={option.value} onClick={() => changeLanguage(option.value)}>
                        {option.icon ? <LanguageImage src={option.icon} alt={option.label} /> : <LanguageStyled />}
                        {option.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </DropdownContainer>
    );
};

export default LanguageSwitcher;

const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
    ::-webkit-scrollbar{
        display: none;
    }
`;

const DropdownButton = styled.button`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    font-weight: bold;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    min-width: 120px;
    text-align: center;
    &:focus {
        outline: none;
    }
`;

const DropdownMenu = styled.div<{ $isOpen: boolean }>`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: ${({ $isOpen }) => ($isOpen ? '200px' : '0')};
    overflow-y: auto;
    transition: max-height 0.25s ease-in-out, opacity 0.25s ease-in-out;
    opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
    pointer-events: ${({ $isOpen }) => ($isOpen ? 'auto' : 'none')};
`;

const DropdownItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    font-weight: bold;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    &:hover {
        background-color: #f0f0f0;
    }
`;

const iconStyles = `
  font-size: 1.5rem;
  color: ${(props:any) => props.theme.colors.text};
`;

const LanguageStyled = styled(MdLanguage)`
    ${iconStyles}
`;

const LanguageImage = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 8px;
`;
