import i18next from 'i18next';


export interface Tendency {
  id: number;
  englishName: string;
  koreanName: string;
  thaiName: string;
  japaneseName: string;
  description: string;
  questions: string[];
}

export const weights: { [key: string]: { [key: string]: number } } = {
  Owner: { Owner: 1, DaddyMommy: 0.8, Dominant: 0.7 },
  DaddyMommy: { DaddyMommy: 1, Owner: 0.8, Dominant: 0.6 },
  Dominant: { Dominant: 1, MasterMistress: 0.7, Boss: 0.7, Owner: 0.7, DaddyMommy: 0.6 },
  Slave: { Slave: 1, Pet: 0.7, Little: 0.6 },
  Pet: { Pet: 1, Slave: 0.7, Little: 0.7 },
  Little: { Little: 1, Pet: 0.7, Slave: 0.6 },

  MasterMistress: { MasterMistress: 1, Dominant: 0.7, Hunter: 0.7, BratTamer: 0.6, Degrader: 0.5, Rigger: 0.5, Boss: 0.7 },
  Hunter: { Hunter: 1, MasterMistress: 0.7, BratTamer: 0.6, Degrader: 0.5, Rigger: 0.5, Boss: 0.6 },
  BratTamer: { BratTamer: 1, MasterMistress: 0.6, Hunter: 0.6, Degrader: 0.5, Rigger: 0.5, Boss: 0.5 },
  Degrader: { Degrader: 1, MasterMistress: 0.5, Hunter: 0.5, BratTamer: 0.5, Rigger: 0.5, Boss: 0.5 },
  Rigger: { Rigger: 1, MasterMistress: 0.5, Hunter: 0.5, BratTamer: 0.5, Degrader: 0.5, Boss: 0.5 },
  Boss: { Boss: 1, MasterMistress: 0.7, Dominant: 0.7, Hunter: 0.6, BratTamer: 0.5, Degrader: 0.5, Rigger: 0.5 },
  Brat: { Brat: 1, Degradee: 0.7, RopeBunny: 0.7, Servant: 0.6, Prey: 0.6, Submissive: 0.6 },
  Degradee: { Degradee: 1, Brat: 0.7, RopeBunny: 0.6, Servant: 0.5, Prey: 0.5, Submissive: 0.5 },
  RopeBunny: { RopeBunny: 1, Brat: 0.7, Degradee: 0.6, Servant: 0.5, Prey: 0.5, Submissive: 0.5 },
  Servant: { Servant: 1, Brat: 0.6, Degradee: 0.5, RopeBunny: 0.5, Prey: 0.5, Submissive: 0.5 },
  Prey: { Prey: 1, Brat: 0.6, Degradee: 0.5, RopeBunny: 0.5, Servant: 0.5, Submissive: 0.5 },
  Submissive: { Submissive: 1, Brat: 0.6, Degradee: 0.5, RopeBunny: 0.5, Servant: 0.5, Prey: 0.5 },

  Sadist: { Sadist: 1, Spanker: 0.7 },
  Spanker: { Spanker: 1, Sadist: 0.7 },
  Masochist: { Masochist: 1, Spankee: 0.7 },
  Spankee: { Spankee: 1, Masochist: 0.7 }
};


export const maxScores: { [key: string]: number } = {
  Dominant: 92.5,
  Submissive: 90,
  Switch: 25,
  Sadist: 42.5,
  Masochist: 42.5,
  Rigger: 87.5,
  RopeBunny: 95,
  MasterMistress: 117.5,
  Slave: 57.5,
  Hunter: 97.5,
  Prey: 90,
  Brat: 105,
  BratTamer: 92.5,
  DaddyMommy: 60,
  Little: 57.5,
  Owner: 62.5,
  Pet: 60,
  Spanker: 42.5,
  Spankee: 42.5,
  Boss: 112.5,
  Servant: 90,
  Degrader: 87.5,
  Degradee: 95
};


export const bdsmTendencies: Tendency[] = [
  {
    id: 0,
    englishName: "Dominant",
    koreanName: "돔(도미넌트)",
    thaiName: "โดมิแนนท์",
    japaneseName: "ドミナント",
    description: i18next.t('bdsmTendencies.Dominant.description'),
    questions: i18next.t('bdsmTendencies.Dominant.questions', { returnObjects: true }) as string[] as string[],
  },
  {
    id: 1,
    englishName: "Submissive",
    koreanName: "섭(서브미시브)",
    thaiName: "ซับมิสซีฟ",
    japaneseName: "サブミッシブ",
    description: i18next.t('bdsmTendencies.Submissive.description'),
    questions: i18next.t('bdsmTendencies.Submissive.questions', { returnObjects: true }) as string[],
  },
  {
    id: 2,
    englishName: "Switch",
    koreanName: "스위치",
    thaiName: "สวิตช์",
    japaneseName: "スイッチ",
    description: i18next.t('bdsmTendencies.Switch.description'),
    questions: i18next.t('bdsmTendencies.Switch.questions', { returnObjects: true }) as string[],
  },
  {
    id: 3,
    englishName: "Sadist",
    koreanName: "사디스트",
    thaiName: "ซาดิสต์",
    japaneseName: "サディスト",
    description: i18next.t('bdsmTendencies.Sadist.description'),
    questions: i18next.t('bdsmTendencies.Sadist.questions', { returnObjects: true }) as string[],
  },
  {
    id: 4,
    englishName: "Masochist",
    koreanName: "마조히스트",
    thaiName: "มาโซคิสต์",
    japaneseName: "マゾヒスト",
    description: i18next.t('bdsmTendencies.Masochist.description'),
    questions: i18next.t('bdsmTendencies.Masochist.questions', { returnObjects: true }) as string[],
  },
  {
    id: 5,
    englishName: "Rigger",
    koreanName: "리거",
    thaiName: "ริกเกอร์",
    japaneseName: "リガー",
    description: i18next.t('bdsmTendencies.Rigger.description'),
    questions: i18next.t('bdsmTendencies.Rigger.questions', { returnObjects: true }) as string[],
  },
  {
    id: 6,
    englishName: "RopeBunny",
    koreanName: "로프버니",
    thaiName: "โร้พบันนี",
    japaneseName: "ロープバニー",
    description: i18next.t('bdsmTendencies.RopeBunny.description'),
    questions: i18next.t('bdsmTendencies.RopeBunny.questions', { returnObjects: true }) as string[],
  },
  {
    id: 7,
    englishName: "MasterMistress",
    koreanName: "마스터/미스트리스",
    thaiName: "มาสเตอร์/มิสเตรส",
    japaneseName: "マスター/ミストレス",
    description: i18next.t('bdsmTendencies.MasterMistress.description'),
    questions: i18next.t('bdsmTendencies.MasterMistress.questions', { returnObjects: true }) as string[],
  },
  {
    id: 8,
    englishName: "Slave",
    koreanName: "슬레이브",
    thaiName: "ทาส",
    japaneseName: "スレイブ",
    description: i18next.t('bdsmTendencies.Slave.description'),
    questions: i18next.t('bdsmTendencies.Slave.questions', { returnObjects: true }) as string[],
  },
  {
    id: 9,
    englishName: "Hunter",
    koreanName: "헌터",
    thaiName: "ฮันเตอร์",
    japaneseName: "ハンター",
    description: i18next.t('bdsmTendencies.Hunter.description'),
    questions: i18next.t('bdsmTendencies.Hunter.questions', { returnObjects: true }) as string[],
  },
  {
    id: 10,
    englishName: "Prey",
    koreanName: "프레이",
    thaiName: "เหยื่อ",
    japaneseName: "プレイ",
    description: i18next.t('bdsmTendencies.Prey.description'),
    questions: i18next.t('bdsmTendencies.Prey.questions', { returnObjects: true }) as string[],
  },
  {
    id: 11,
    englishName: "Brat",
    koreanName: "브랫",
    thaiName: "เด็กแสบ",
    japaneseName: "ブラット",
    description: i18next.t('bdsmTendencies.Brat.description'),
    questions: i18next.t('bdsmTendencies.Brat.questions', { returnObjects: true }) as string[],
  },
  {
    id: 12,
    englishName: "BratTamer",
    koreanName: "브랫 테이머",
    thaiName: "ผู้ปราบเด็กแสบ",
    japaneseName: "ブラットテイマー",
    description: i18next.t('bdsmTendencies.BratTamer.description'),
    questions: i18next.t('bdsmTendencies.BratTamer.questions', { returnObjects: true }) as string[],
  },
  {
    id: 13,
    englishName: "DaddyMommy",
    koreanName: "대디/맘미",
    thaiName: "แดดดี้/มัมมี่",
    japaneseName: "ダディ/マミー",
    description: i18next.t('bdsmTendencies.DaddyMommy.description'),
    questions: i18next.t('bdsmTendencies.DaddyMommy.questions', { returnObjects: true }) as string[],
  },
  {
    id: 14,
    englishName: "Little",
    koreanName: "리틀",
    thaiName: "ลิตเติ้ล",
    japaneseName: "リトル",
    description: i18next.t('bdsmTendencies.Little.description'),
    questions: i18next.t('bdsmTendencies.Little.questions', { returnObjects: true }) as string[],
  },
  {
    id: 15,
    englishName: "Owner",
    koreanName: "오너",
    thaiName: "เจ้าของ",
    japaneseName: "オーナー",
    description: i18next.t('bdsmTendencies.Owner.description'),
    questions: i18next.t('bdsmTendencies.Owner.questions', { returnObjects: true }) as string[],
  },
  {
    id: 16,
    englishName: "Pet",
    koreanName: "펫",
    thaiName: "สัตว์เลี้ยง",
    japaneseName: "ペット",
    description: i18next.t('bdsmTendencies.Pet.description'),
    questions: i18next.t('bdsmTendencies.Pet.questions', { returnObjects: true }) as string[],
  },
  {
    id: 17,
    englishName: "Spanker",
    koreanName: "스팽커",
    thaiName: "ผู้ตบ",
    japaneseName: "スパンカー",
    description: i18next.t('bdsmTendencies.Spanker.description'),
    questions: i18next.t('bdsmTendencies.Spanker.questions', { returnObjects: true }) as string[],
  },
  {
    id: 18,
    englishName: "Spankee",
    koreanName: "스팽키",
    thaiName: "ผู้ถูกตบ",
    japaneseName: "スパンキー",
    description: i18next.t('bdsmTendencies.Spankee.description'),
    questions: i18next.t('bdsmTendencies.Spankee.questions', { returnObjects: true }) as string[],
  },
  {
    id: 19,
    englishName: "Boss",
    koreanName: "보스",
    thaiName: "บอส",
    japaneseName: "ボス",
    description: i18next.t('bdsmTendencies.Boss.description'),
    questions: i18next.t('bdsmTendencies.Boss.questions', { returnObjects: true }) as string[],
  },
  {
    id: 20,
    englishName: "Servant",
    koreanName: "서번트",
    thaiName: "ผู้รับใช้",
    japaneseName: "サーバント",
    description: i18next.t('bdsmTendencies.Servant.description'),
    questions: i18next.t('bdsmTendencies.Servant.questions', { returnObjects: true }) as string[],
  },
  {
    id: 21,
    englishName: "Degrader",
    koreanName: "디그레이더",
    thaiName: "ผู้ทำให้ต่ำต้อย",
    japaneseName: "デグレーダー",
    description: i18next.t('bdsmTendencies.Degrader.description'),
    questions: i18next.t('bdsmTendencies.Degrader.questions', { returnObjects: true }) as string[],
  },
  {
    id: 22,
    englishName: "Degradee",
    koreanName: "디그레이디",
    thaiName: "ผู้ถูกทำให้ต่ำต้อย",
    japaneseName: "デグラディー",
    description: i18next.t('bdsmTendencies.Degradee.description'),
    questions: i18next.t('bdsmTendencies.Degradee.questions', { returnObjects: true }) as string[],
  }
];
