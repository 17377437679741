import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '../../hooks/useResponsive';

type Props = {};

type TendencyData = {
    [key: string]: string;
};

const categories = {
    powerExchange: ['Dominant', 'Submissive', 'Switch'],
    painPleasure: ['Sadist', 'Masochist'],
    bondage: ['Rigger', 'RopeBunny'],
    ownership: ['MasterMistress', 'Slave', 'Owner', 'Pet'],
    rolePlay: ['DaddyMommy', 'Little', 'Hunter', 'Prey'],
    discipline: ['Brat', 'BratTamer'],
    impact: ['Spanker', 'Spankee'],
    service: ['Boss', 'Servant'],
    humiliation: ['Degrader', 'Degradee']
};

// const useTendencyData = (language:string) => {
//     return useMemo(async () => {
//         const data: TendencyData = {};
//         for (const category of Object.values(categories).flat()) {
//             try {
//                 const response = await fetch(`/locales/${language}/${category}.json`);
//                 const tendencyInfo = await response.json();
//                 data[category] = tendencyInfo.name;
//             } catch (error) {
//                 // console.error(`Error fetching data for ${category}:`, error);
//             }
//         }
//         return data;
//     }, [language]);
// };

const TendenciesMainPage: React.FC<Props> = () => {
    const { t, i18n } = useTranslation();
    const { isDesktop, isTablet, isMobile } = useResponsive();
    // const tendencyData = useTendencyData(i18n.language);


    return (
        <Container>
            <Area isDesktop={isDesktop}>
                {Object.entries(categories).map(([category, tendencyList]) => (
                    <CategorySection key={category}>
                        <CategoryTitle>{t(`categories.${category}`)}</CategoryTitle>
                        <TendencyGrid>
                            {tendencyList.map((tendency) => (
                                <TendencyCard key={tendency}>
                                    <StyledLink to={`/tendencies/${tendency.toLowerCase()}`}>
                                        {t(`bdsmTendencies.${tendency}.name`)}
                                    </StyledLink>
                                </TendencyCard>
                            ))}
                        </TendencyGrid>
                    </CategorySection>
                ))}
            </Area>
        </Container>
    );
};

export default TendenciesMainPage;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.colors.background};
  padding: 20px 0;
`;

const Area = styled.div<{ isDesktop: boolean }>`
    max-width: 1024px;
    width: 100%;
    padding: ${(props) => (props.isDesktop ? '0px 10px' : '0px 12px')};
`;

const CategorySection = styled.section`
    margin-bottom: 30px;
`;

const CategoryTitle = styled.h2`
    font-size: 1.4rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.text};
    margin-bottom: 15px;
    border-bottom: 2px solid #333;
    padding-bottom: 5px;
`;

const TendencyGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
`;

const TendencyCard = styled.div`
    color: ${(props) => props.theme.colors.background};
    border-radius: 8px;
    box-shadow: 0 2px 4px ${(props) => props.theme.colors.secondary};
    
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
    }
`;

const StyledLink = styled(Link)`
    display: block;
    padding: 0.8rem;
    text-decoration: none;
    color: ${(props) => props.theme.colors.text};
    font-weight: 600;
    font-size: 1.1rem;
    text-align: center;

    &:hover {
        background-color: #f0f0f0;
        border-radius: 8px;
    }
`;
