import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../ProgressBar';
import Question from '../Question';

import { bdsmTendencies, weights } from '../../data/data';
import { MdOutlineNavigateNext } from "react-icons/md";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface QuestionType {
    id: number;
    text: string;
    tendencyId: number;
}

const QUESTIONS_PER_PAGE = 5;

const Test: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [allQuestions, setAllQuestions] = useState<QuestionType[]>([]);
    const [scores, setScores] = useState<{ tendency: string, koreanName: string, score: number }[]>([]);
    const [answers, setAnswers] = useState<{ [key: number]: number | undefined }>({});
    const navigate = useNavigate();

    const shuffleArray = useCallback((array: QuestionType[]): QuestionType[] => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    }, []);

    useEffect(() => {
        const loadQuestions = () => {
            const localizedTendencies = bdsmTendencies.map((tendency) => ({
                ...tendency,
                description: t(`bdsmTendencies.${tendency.englishName}.description`),
                questions: t(`bdsmTendencies.${tendency.englishName}.questions`, { returnObjects: true }) as string[]
            }));

            const questions = localizedTendencies.flatMap((tendency, index) => {
                const tendencyQuestions = Array.isArray(tendency.questions) ? tendency.questions : [];
                return tendencyQuestions.map((q, qIndex) => ({
                    id: index * 100 + qIndex,
                    text: q,
                    tendencyId: index
                }));
            });

            setAllQuestions(shuffleArray(questions));
            setScores(localizedTendencies.map(t => ({ tendency: t.englishName, koreanName: t.koreanName, score: 0 })));
        };

        loadQuestions();
    }, [i18n.language, t, shuffleArray]);

    const handleAnswer = useCallback((questionId: number, score: number) => {
        const question = allQuestions.find(q => q.id === questionId);
        if (question) {
            const tendencyName = bdsmTendencies[question.tendencyId]?.englishName;
            if (tendencyName) {
                setScores(prevScores => {
                    const newScores = [...prevScores];
                    const previousScore = answers[questionId] || 0;
                    const scoreDifference = score - previousScore;
                    newScores[question.tendencyId].score += scoreDifference;

                    const weight = weights[tendencyName];

                    for (const relatedTendency in weight) {
                        const relatedTendencyIndex = bdsmTendencies.findIndex(t => t.englishName === relatedTendency);
                        if (relatedTendencyIndex !== -1 && relatedTendencyIndex !== question.tendencyId) {
                            newScores[relatedTendencyIndex].score += scoreDifference * weight[relatedTendency];
                        }
                    }

                    return newScores;
                });

                setAnswers(prev => ({ ...prev, [questionId]: score }));
            }
        }
    }, [allQuestions, answers]);

    const nextPage = useCallback(() => {
        if ((currentPage + 1) * QUESTIONS_PER_PAGE >= allQuestions.length) {
            navigate('/results', { state: { scores } });
        } else {
            setCurrentPage(prev => prev + 1);
        }
    }, [currentPage, allQuestions.length, scores, navigate]);

    const currentQuestions = useMemo(() => {
        return allQuestions.slice(
            currentPage * QUESTIONS_PER_PAGE,
            (currentPage + 1) * QUESTIONS_PER_PAGE
        );
    }, [allQuestions, currentPage]);

    return (
        <TestContainer>
            <ProgressBar current={(currentPage + 1) * QUESTIONS_PER_PAGE} total={allQuestions.length} />
            {currentQuestions.map((question) => (
                <Question
                    key={question.id}
                    question={question}
                    onAnswer={handleAnswer}
                    selectedAnswer={answers[question.id] !== undefined ? answers[question.id] : null}
                />
            ))}
            <ButtonArea>
                <NextButton onClick={nextPage}>
                    <NextText>{t('next')}</NextText>
                    <MdOutlineNavigateNext size={30} />
                </NextButton>
            </ButtonArea>
        </TestContainer>
    );
};

export default React.memo(Test);

const TestContainer = styled.div`
    padding: 20px;
`;

const ButtonArea = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
`;

const NextButton = styled.button`
    width: 40%;
    max-width: 1024px;
    height: 3rem;
    border: 1px solid #000;
    border-radius: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const NextText = styled.span`
    font-weight: bold;
`;