import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import LanguageSwitcher from '../LanguageSwitcher';
import { useTheme } from '../../styles/ThemeContext';
import { HiLightBulb } from "react-icons/hi";
import { MdManageSearch } from "react-icons/md";
import logoLight from '../../images/bdsm_light.png';
import logoDark from '../../images/bdsm_dark.png';
import {useTranslation} from 'react-i18next';

import {useResponsive} from '../../hooks/useResponsive';

const ThemeToggle: React.FC = () => {
    const { theme, toggleTheme } = useTheme();

    return (
        <ToggleButton onClick={toggleTheme}>
            <HiLightBulbStyled />
        </ToggleButton>
    );
};

const Layout: React.FC = () => {
    const { theme } = useTheme();
    const { t } = useTranslation();
    const {isDesktop, isTablet, isMobile} = useResponsive();

    return (
        <>
            <HeaderContainer>
                <HeaderArea>
                    <Link to={'/'} className={'decoration-0'}>
                        <Logo src={theme === 'light' ? logoLight : logoDark} alt="Logo" />
                    </Link>
                    <ToggleArea>
                        <Link to={'/tendencies'} >
                            {isMobile ?
                                <MdManageSearch size={22}/>
                                :
                                <ExploreText>{t('tendencies')}</ExploreText>
                            }
                        </Link>
                        <ThemeToggle />
                        <LanguageSwitcher />
                    </ToggleArea>
                </HeaderArea>
            </HeaderContainer>
            <Outlet />
        </>
    );
};

export default Layout;

const HeaderContainer = styled.div`
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
`;

const Logo = styled.img`
    height: 60px;
`;

const HeaderArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1024px;
`;

const ToggleArea = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const ExploreText = styled.p`
    font-weight: bold;
    font-size: 1.1rem;
`

const OutletContainer = styled.div`
    padding: 20px;
`;

const ToggleButton = styled.button`
    background: none;
    border: none;
    color: ${(props) => props.theme.colors.text};
    padding: 10px;
    cursor: pointer;
`;

const HiLightBulbStyled = styled(HiLightBulb)`
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.text};
`;
